<template>
  <TheHeader />
  <AppOther />
</template>

<script>
import { AppOther } from '@/features/Other'

export default {
  components: { AppOther },
}
</script>
