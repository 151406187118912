<template>
  <TheHeader />
  <AppCompare />
</template>

<script>
import { AppCompare } from '@/features/CompareTools'

export default {
  components: { AppCompare },
}
</script>
